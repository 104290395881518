<template>
  <!-- id : contents : (S) -->
  <div id='contents' class="st-login" >
    <!-- class : content-wrap : (S) -->
    <section class="content-wrap st-center">
      <!-- class : main-wrap (S) -->
      <div class="main-wrap">
        <div class="join-result-wrap">

          <div class="result-msg-box">
            <div class="txt1"><strong>{{ name }}</strong> 님의 회원가입 신청이 완료되었습니다.</div>
            <div class="txt2">관리자에서 확인 후 가입완료를 도와드리도록 하겠습니다. 감사합니다.</div>
          </div>
          <div class="btn-box">
            <a href="javascript:;" @click="onClickComplete" class="btn-submit">확인</a>
          </div>
        </div>
      </div>
      <!-- class : main-wrap (E) -->


    </section>
    <!-- class : content-wrap : (E) -->
  </div>
  <!-- id : contents : (E) -->
</template>

<script>
export default {
  name: "joinResult",
  data(){
    return{
      name:this.$route.params.name
    }
  },

  methods:{
    onClickComplete(){
      this.$router.push('/main');
      //window.location.href = `/main`
    }
  }
}
</script>

<style scoped>

</style>